import React from 'react';
import './Heading.css';

const Heading = () => {
  return (
    <div className="heading-container">
      <a href="#contact" className="contact-us-button">Contact Us</a>
      <div className="heading">
        <h1 className="main-heading">Welcome to Hi-Tech Developers</h1>
      </div>
      <div className="subheading">
        <h3 className="subheading-text">
          At Hi-Tech Developers, we excel in professional planning and estimation
          for playgrounds, ensuring precision and quality in every project. Using
          only high-quality, long-lasting materials, we construct pitches and play
          areas that stand the test of time. Elevate your turf with our imported
          lighting and fencing solutions, adding beauty and functionality to your
          sports facility. Partner with Hi-Tech Developers for your turf project
          and experience excellence in craftsmanship and innovation.
        </h3>
      </div>
      
    </div>
  );
};

export default Heading;
