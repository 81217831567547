import React from "react";
import Slider from "react-slick";
import image1 from "../images/projects/1.jpg";
import image2 from "../images/projects/2.jpg";
import image3 from "../images/projects/3.jpg";
import image4 from "../images/projects/4.jpg";
import image5 from "../images/projects/5.jpg";
import image6 from "../images/projects/6.jpg";
import image7 from "../images/projects/7.jpg";
import image8 from "../images/projects/8.jpg";
import image9 from "../images/projects/9.jpg";
import "./Projects.css";

const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
];

const Projects = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section id="projects" className="projects-section">
      <div className="heading">
        <h1>Projects</h1>
      </div>
      <Slider {...settings} className="projects-slider">
        {images.map((image, index) => (
          <div key={index} className="project-card">
            <img src={image} alt={`Project ${index + 1}`} className="project-image" />
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default Projects;
