import React, { useState } from "react";
import { scrollToRef } from "../helper";
import "./Headerheadings.css";

const Headerheadings = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = (ref) => {
    scrollToRef(ref);
    setIsMenuOpen(false); // Close menu after click
  };

  return (
    <header className="header-container">
      
      <nav className={`header-headings ${isMenuOpen ? "open" : ""}`}>
        <h2 onClick={() => handleLinkClick("aboutPage")}>About Us</h2>
        <h2 onClick={() => handleLinkClick("services")}>Services</h2>
        <h2 onClick={() => handleLinkClick("projects")}>Projects</h2>
        <h2 onClick={() => handleLinkClick("contact")}>Contact</h2>
      </nav>
      <button className="menu-button" onClick={handleMenuToggle}>
        ☰
      </button>
    </header>
  );
};

export default Headerheadings;
