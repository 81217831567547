import React from 'react';
import homeImage from '../images/1.jpg';
import './HomeImage.css';

const HomeImage = () => {
  return (
    <div className="home-image-container">
      <img src={homeImage} alt="Home Image" className="home-image" />
    </div>
  );
};

export default HomeImage;
