import React from 'react';
import image1 from '../images/projects/4.jpg';
import image2 from '../images/projects/8.jpg';
import image3 from '../images/Services/Cricket.jpeg';
import image4 from '../images/Services/Volleyball.jpeg';
import image5 from '../images/Services/Basketball.jpeg';
import './Services.css';

const Services = () => {
  const serviceImages = [image1, image2, image3, image4, image5];
  const serviceTitles = ["Football", "Badminton", "Cricket", "Volleyball", "Basketball"];

  return (
    <section id="services" className="services-section">
      <div className="services-heading">
        <h1>Services</h1>
      </div>
      <div className="services-container">
        {serviceImages.map((image, index) => (
          <div key={index} className="service-card">
            <img src={image} alt={serviceTitles[index]} className="service-image" />
            <h2 className="service-title">{serviceTitles[index]}</h2>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
