import React from "react";
import Logo from "../component/Logo";
import Headerheadings from "../component/Headerheadings";
import "./Header.css";

const Header = () => {
  return (
    <header className="header">
      <Logo />
      <Headerheadings />
    </header>
  );
};

export default Header;
