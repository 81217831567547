// Import React and necessary components
import React from "react";
import "./App.css";
import Header from "./pages/Header";
import HomePage from "./pages/Homepage";
import AboutPage from "./pages/AboutPage";
import Heading from "./component/Heading";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Projects from "./pages/Projects";
import Footer from "./pages/Footer";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAM2SUxtqBX5f7DK0_qFYwLyjC8MlbiX-E",
  authDomain: "hitech-developers.firebaseapp.com",
  databaseURL: "https://hitech-developers-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "hitech-developers",
  storageBucket: "hitech-developers.appspot.com",
  messagingSenderId: "445076385154",
  appId: "1:445076385154:web:bb1d53713b79989dc7739b",
  measurementId: "G-QBCDVQVFLB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const App = () => {
  return (
    <div className="container">
      <Header />
      <HomePage />
      <AboutPage />
      <Services />
      <Projects />
      <Contact />
      {/* <Footer/> */}
    </div>
  );
};

export default App;
