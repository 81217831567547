const scrollToRef = (sectionId) => {
  const section = document.getElementById(sectionId);

  if (section) {
    const headerElement = document.querySelector(".header");

    if (headerElement) { // Ensure that the element exists
      const headerHeight = headerElement.offsetHeight; // Get header height
      const sectionTop = section.offsetTop - headerHeight; // Calculate target position
      window.scrollTo({
        top: sectionTop,
        behavior: "smooth",
      });
    } else {
      console.warn("Header element not found.");
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  } else {
    console.warn(`Section with ID ${sectionId} not found.`);
  }
};

export {
    scrollToRef
}