import React from "react";
import image1 from "../icons/instagram.png";
import image2 from "../icons/facebook.png";
import image3 from "../icons/youtube.png";
import whatsappIcon from "../icons/whatsapp.png"; // Import WhatsApp icon
import "./Contact.css"; // Import the CSS file

const Contact = () => {
  const instagramLink = "https://www.instagram.com/your-instagram-account"; // Replace with your Instagram URL
  const facebookLink = "https://www.facebook.com/your-facebook-account"; // Replace with your Facebook URL
  const youtubeLink = "https://www.youtube.com/your-youtube-account"; // Replace with your YouTube URL
  const whatsappLink = "https://wa.me/+919567752057"; // Replace with your WhatsApp number

  // Function to handle WhatsApp button click
  const handleWhatsAppClick = () => {
    window.open(whatsappLink, "_blank");
  };

  return (
    <section id="contact" className="contact-section">
      <div className="heading">
        <h1>Contact Us</h1>
      </div>

      <div className="contact-container">
        <div className="form-container">
          <h2>Contact Form</h2>
          <form>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input type="tel" id="phone" name="phone" required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                rows="4"
                required
              ></textarea>
            </div>
            <button type="submit">Send</button>
          </form>
        </div>

        <div className="contact-info">
          <h3>HiTech Developers</h3>
          <p>
            Room No: 100
            <br />
            Pettannagadi,
            <br />
            Vaidyarangadi P.O,
            <br />
            Malappuram, Kerala 673633
          </p>
{/* WhatsApp Button */}
<div className="whatsapp-button" onClick={handleWhatsAppClick}>
            <img src={whatsappIcon} alt="WhatsApp Icon" className="whatsapp-icon" />
          </div>
          <a href="tel:9567752057" className="call-button">
            Mob 1: 9567752057
          </a>
          <a href="tel:8139052692" className="call-button">
            Mob 2: 8139052692
          </a>

          
        </div>

        <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1001988.7483838882!2d74.73114764687502!3d11.186262300000013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba64f3c835f97dd%3A0x4da5fb5c95db2f91!2sHi%20Tech%20Developers!5e0!3m2!1sen!2sin!4v1714463904535!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </div>

      <div className="follow-us">
        <h2>Follow Us</h2>
        <div className="social-icons">
          {[image1, image2, image3].map((image, index) => (
            <div key={index} className="social-icon">
              {/* Conditionally render link based on index */}
              {index === 0 ? (
                <a href={instagramLink} target="_blank" rel="noopener noreferrer">
                  <img src={image} alt={`Social icon ${index + 1}`} />
                </a>
              ) : index === 1 ? (
                <a href={facebookLink} target="_blank" rel="noopener noreferrer">
                  <img src={image} alt={`Social icon ${index + 1}`} />
                </a>
              ) : index === 2 ? (
                <a href={youtubeLink} target="_blank" rel="noopener noreferrer">
                  <img src={image} alt={`Social icon ${index + 1}`} />
                </a>
              ) : (
                <img src={image} alt={`Social icon ${index + 1}`} onClick={handleWhatsAppClick} style={{ cursor: 'pointer' }} />
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Contact;
