import React from "react";
import { scrollToRef } from "../helper";
import logoImage from "../icons/Logo.png"; // Import the logo image
import "./Logo.css";

const Logo = () => {
  return (
    <div className="logo">
      <img 
        src={logoImage} 
        alt="Logo" 
        onClick={() => scrollToRef("homePage")} 
        style={{ width: "300px", height: "auto" }} // Adjust the size as needed
      />
    </div>
  );
};

export default Logo;
