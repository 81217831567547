import React from 'react';
import aboutUsImage from '../images/aboutUs.jpg';
import './AboutPage.css';

const AboutPage = () => {
  return (
    <section id="aboutPage" className="about-page">
      <div className="heading">
        <h1>About Us</h1>
      </div>
      <div className="content">
        <div className="image-container">
          <img src={aboutUsImage} alt="About Us" />
        </div>
        <div className="text-container">
          <h3>
            At Hi-Tech Developers, we specialize in building high-quality sports
            infrastructure and recreational areas. Our experienced team is
            dedicated to delivering top-notch construction services, from
            planning and estimating to project completion.
            <br />
            <br />
            We use premium materials to ensure durability and safety in every
            project. Our comprehensive approach includes imported lighting and
            fencing solutions, adding functionality and style to your sports
            facilities.
            <br />
            <br />
            Customer satisfaction is our top priority. We work closely with you
            to understand your vision and exceed expectations. Thank you for
            choosing Hi-Tech Developers. Let's create something great together.
          </h3>
        </div>
      </div>
    </section>
  );
};

export default AboutPage;
