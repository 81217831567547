import React from "react";
import Heading from "../component/Heading";
import HomeImage from "../component/HomeImage";
import "./Homepage.css";

const HomePage = () => {
  return (
    <section id="homePage" className="home-page">
      <div className="heading-container">
        <Heading />
      </div>
      <div className="image-container">
        <HomeImage />
      </div>
    </section>
  );
};

export default HomePage;
